import {
	Button,
	Checkbox,
	Heading,
	Input,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	Select,
	Text
} from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import logoUrl from "../assets/logo.png"
import Loader from "./Loader"
import styles from "./Ring.module.css"
import { useDidMountEffect } from "../hooks"
import { downscaleImage } from "../utils/image"
import { calculatePriceForRing1 } from "../utils/price"
import { insertStringAtIndex } from "../utils/string"
import { API_ENDPOINT } from ".."
import { useParams } from "react-router"

const MIN_TEXT_LENGTH = 2
const MAX_TEXT_LENGTH = 12
const SIZES = new Array(30 * 2)
	.fill(0)
	.map((_, i) => (i >= 2 ? i / 2 : 0))
	.filter(Boolean)
const MATERIALS = ["YELLOW GOLD", "PINK GOLD", "WHITE GOLD"]
const SYMBOLS = [
	"♥",
	// "♥(upsidedown)",
	"♡",
	// "♡(upsidedown)",
	// "⚡",
	// "∞",
	"★",
	"☆"
	// "✿"
	// "✿(filled)"
]
export const DIAMOND_MATERIALS = [
	"RUBY RED",
	"BLACK",
	"SAPHIRE",
	"WHITE",
	"EMERALD"
]

const Ring1 = () => {
	const { ticket } = useParams<any>()

	const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(true)
	const [mightTakeABitLoader, setMightTakeABitLoader] = useState<boolean>(false)

	const [size, setSize] = useState<number>(29)
	const [material, setMaterial] = useState<string>(MATERIALS[0])
	const [text, setText] = useState<string>("Sample")
	const [isWithDiamonds, setIsWithDiamonds] = useState<boolean>(true)
	const [diamondsMaterial, setDiamondsMaterial] = useState<string>(
		DIAMOND_MATERIALS[1]
	)
	const [letterIndexesWithDiamonds, setLetterIndexesWithDiamonds] = useState<
		Array<number>
	>([0, 1, 2, 3, 4, 5])
	const [price, setPrice] = useState<number>(0)

	const [currentTextSelectionStart, setCurrentTextSelectionStart] =
		useState<number>(0)

	const shapeDiverApiRef = useRef<any | null>(null)
	const updateTextTimeoutRef = useRef<any>()

	const onBuyNowClick = async () => {
		setIsLoaderVisible(true)
		setMightTakeABitLoader(true)

		const downloadSTLResponse =
			await shapeDiverApiRef.current.exports.requestAsync({
				name: "Download",
				silent: true
			})
		console.log("downloadSTLResponse", downloadSTLResponse)
		const stlUrl = downloadSTLResponse.data.content[0]?.href
		if (!stlUrl) {
			alert("Error while creating your model, please retry")

			setIsLoaderVisible(false)
			setMightTakeABitLoader(false)

			return
		}

		console.log("STL URL", stlUrl)

		const paramsRequest = shapeDiverApiRef.current.parameters.get()

		console.log("Params", paramsRequest)

		const params = paramsRequest.data

		params.push({
			name: "Letters with Diamonds",
			value: letterIndexesWithDiamonds.map((_li) => text.charAt(_li)).join(", ")
		})

		const description = params
			.filter(
				(param: any) =>
					!new Array(25 + 1)
						.fill(0)
						.map((_, i) => i.toString())
						.includes(param.name)
			)
			.map((param: any) => `${param.name}: ${param.value}`)
			.join("<br>")

		await shapeDiverApiRef.current.scene.camera.zoomAsync()

		await new Promise((res) => setTimeout(res, 400))

		const screenshotRes =
			await shapeDiverApiRef.current.scene.getScreenshotAsync()

		const attachment = screenshotRes.data
		const compressedAttachment = await downscaleImage(
			attachment,
			"image/png",
			720,
			75
		)

		fetch(`${API_ENDPOINT}/buy`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				ring: 1,
				description,
				stlUrl,
				attachment: compressedAttachment
			})
		})
			.then((res) => res.json())
			.then((res) => {
				console.log("Response", res)

				if (res.success) {
					console.log("Redirecting...")
					window.location.href = res.redirectUrl
					return
				}

				alert(
					`Error: ${
						typeof res.error === "string"
							? res.error
							: JSON.stringify(res.error)
					}`
				)
			})
			.catch((err) => {
				console.error(err)
			})
			.finally(() => {
				setIsLoaderVisible(false)
				setMightTakeABitLoader(false)
			})
	}

	// Setup ShapeDiver
	useEffect(() => {
		if (!ticket) {
			return alert("Invalid ticket")
		}

		// @ts-ignore
		const sdv = new window.SDVApp.ParametricViewer({
			container: document.getElementById("sdv-viewer-container"),
			ticket,
			modelViewUrl: "eu-central-1"
		})
		shapeDiverApiRef.current = sdv

		setIsLoaderVisible(false)

		console.log("ShapeDiver API ready", shapeDiverApiRef.current)

		setTimeout(() => {
			const paramsRequest = shapeDiverApiRef.current.parameters.get()
			console.log("Params", paramsRequest)
		}, 1000)
	}, [ticket])

	useDidMountEffect(() => {
		shapeDiverApiRef.current &&
			shapeDiverApiRef.current.parameters
				.updateAsync({
					name: "Taglia",
					value: size.toString()
				})
				.then(console.log)
	}, [size])

	useDidMountEffect(() => {
		if (text.length > MIN_TEXT_LENGTH) {
			if (updateTextTimeoutRef.current) {
				clearTimeout(updateTextTimeoutRef.current)
			}

			updateTextTimeoutRef.current = setTimeout(() => {
				shapeDiverApiRef.current &&
					shapeDiverApiRef.current.parameters
						.updateAsync({
							name: "Text",
							value: text
						})
						.then((res: any) => console.log("Text updated", res))
			}, 500)

			setLetterIndexesWithDiamonds([])
		}

		return () => {
			if (updateTextTimeoutRef.current) {
				clearTimeout(updateTextTimeoutRef.current)
				updateTextTimeoutRef.current = undefined
			}
		}
	}, [text])

	useDidMountEffect(() => {
		shapeDiverApiRef.current &&
			shapeDiverApiRef.current.parameters
				.updateAsync({
					name: "MATERIALS",
					value: MATERIALS.findIndex((_material) => _material === material)
				})
				.then(console.log)
	}, [material])

	useDidMountEffect(() => {
		if (!isWithDiamonds) {
			setLetterIndexesWithDiamonds([])
		}
	}, [isWithDiamonds])

	useDidMountEffect(() => {
		if (!shapeDiverApiRef.current) {
			return
		}

		text.split("").forEach((_, letterIndex) => {
			const isOn = letterIndexesWithDiamonds.includes(letterIndex)

			shapeDiverApiRef.current.parameters
				.updateAsync({
					name: letterIndex.toString(),
					value: isOn
				})
				.then(console.log)
		})
	}, [letterIndexesWithDiamonds])

	useDidMountEffect(() => {
		shapeDiverApiRef.current &&
			shapeDiverApiRef.current.parameters
				.updateAsync({
					name: "STONES",
					value: DIAMOND_MATERIALS.findIndex((_dm) => _dm === diamondsMaterial)
				})
				.then(console.log)
	}, [diamondsMaterial])

	// Update price
	useEffect(() => {
		setPrice(calculatePriceForRing1(letterIndexesWithDiamonds.length))
	}, [
		text,
		material,
		isWithDiamonds,
		diamondsMaterial,
		letterIndexesWithDiamonds
	])

	return (
		<div className={styles.container}>
			<nav className={styles.nav}>
				<img height="60%" className={styles.navLogo} src={logoUrl} alt="Logo" />
				<Button className={styles.buyBtn} onClick={onBuyNowClick}>
					Prenota ora
				</Button>
			</nav>

			<main className={styles.main}>
				<div
					className={styles.splitScreenDivStyles}
					style={{ width: 100, height: 100 }}
					id="sdv-viewer-container"
				/>
				<div className={styles.splitScreenDivStyles}>
					<div className={styles.controlsWrapper}>
						<Heading className={styles.controlsTitle}>Size</Heading>
						<Select
							className={styles.controlsSelect}
							value={size}
							onChange={(e) => {
								setSize(Number(e.target.value))
							}}
						>
							{SIZES.map((_size, i) => (
								<option key={`size-${i}`} value={_size}>
									{_size.toLocaleString()}
								</option>
							))}
						</Select>

						<Heading className={styles.controlsTitle}>Materials</Heading>
						<Select
							className={styles.controlsSelect}
							value={material}
							onChange={(e) => {
								setMaterial(e.target.value)
							}}
						>
							{MATERIALS.map((_material, i) => (
								<option key={`material-${i}`} value={_material}>
									{_material}
								</option>
							))}
						</Select>

						<Heading className={styles.controlsTitle}>Text</Heading>
						<Input
							value={text}
							onBlur={(e) => {
								setCurrentTextSelectionStart(e.target.selectionStart || 0)
							}}
							onChange={(e) => {
								if (e.target.value.length <= MAX_TEXT_LENGTH) {
									setText(e.target.value)
								}
							}}
							className={styles.controlsSelect}
						/>
						<Text className={styles.controlsSubtitle} fontSize="lg">
							Size
						</Text>
						{SYMBOLS.map((_symbol) => (
							<span
								key={`symbol-${_symbol}`}
								className={styles.controlsSymbol}
								onClick={() => {
									if (text.length + _symbol.length <= MAX_TEXT_LENGTH) {
										setText(
											insertStringAtIndex(
												text,
												currentTextSelectionStart,
												_symbol
											)
										)
									}
								}}
							>
								{_symbol}
							</span>
						))}

						<Heading className={styles.controlsTitle}>Diamonds</Heading>
						<Checkbox
							className={styles.controlsCheckbox}
							isChecked={isWithDiamonds}
							onChange={() => {
								setIsWithDiamonds(!isWithDiamonds)
							}}
						>
							Add Diamonds
						</Checkbox>
						{isWithDiamonds && (
							<>
								<div className={styles.lettersDiamondToggles}>
									<Menu closeOnSelect={false}>
										<MenuButton
											as={Button}
											className={styles.lettersWithDiamondsMenuButton}
											colorScheme="blue"
										>
											{letterIndexesWithDiamonds.length == 0
												? "No letters"
												: letterIndexesWithDiamonds
														.sort()
														.map((_li) => text.charAt(_li))
														.join(", ")}
										</MenuButton>
										<MenuList
											minWidth="240px"
											style={{
												height: 140,
												overflowY: "auto"
											}}
										>
											{text.split("").map((letter, index) => {
												const isToggledOn =
													letterIndexesWithDiamonds.includes(index)

												return (
													<MenuItemOption
														key={`letter-${letter}-${index}`}
														value={index.toString()}
														isChecked={isToggledOn}
														onClick={() => {
															console.log(
																"Toggling",
																letter,
																index,
																"which is currently",
																isToggledOn,
																"because letterIndexesWithDiamonds is",
																letterIndexesWithDiamonds
															)

															let newLetterIndexesWithDiamonds = [
																...letterIndexesWithDiamonds
															]
															if (isToggledOn) {
																newLetterIndexesWithDiamonds =
																	newLetterIndexesWithDiamonds.filter(
																		(lI) => lI !== index
																	)
															} else {
																newLetterIndexesWithDiamonds.push(index)
															}
															setLetterIndexesWithDiamonds(
																newLetterIndexesWithDiamonds
															)
														}}
													>
														{letter}
													</MenuItemOption>
												)
											})}
										</MenuList>
									</Menu>
								</div>

								<Select
									style={{ marginTop: 16 }}
									className={styles.controlsSelect}
									value={diamondsMaterial}
									onChange={(e) => {
										setDiamondsMaterial(e.target.value)
									}}
								>
									{DIAMOND_MATERIALS.map((_diamondsMaterial, i) => (
										<option
											key={`diamonds-material-${i}`}
											value={_diamondsMaterial}
										>
											{_diamondsMaterial}
										</option>
									))}
								</Select>
							</>
						)}

						{price > 0 && (
							<Text
								className={[styles.controlsTitle, styles.controlsPrice].join(
									" "
								)}
								fontSize="2xl"
							>
								Price: {price}€
							</Text>
						)}
					</div>
				</div>

				<Loader
					isVisible={isLoaderVisible}
					mightTakeABit={mightTakeABitLoader}
				/>
			</main>
		</div>
	)
}

export default Ring1
