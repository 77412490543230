import { ChakraProvider } from "@chakra-ui/react"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Ring1 from "./components/Ring1"
import Ring2 from "./components/Ring2"
import "./index.css"

export const API_ENDPOINT =
	"https://shapediver-shopify-api.matteo-lobello.workers.dev"

ReactDOM.render(
	<React.StrictMode>
		<ChakraProvider>
			<Router>
				<Switch>
					<Route exact path="/ring-1/:ticket" component={Ring1} />
					<Route exact path="/ring-2/:ticket" component={Ring2} />
				</Switch>
			</Router>
		</ChakraProvider>
	</React.StrictMode>,
	document.getElementById("root")
)
