import { Button, Heading, Input, Select, Text } from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import Loader from "../components/Loader"
import { useDidMountEffect } from "../hooks"
import { downscaleImage } from "../utils/image"
import { calculatePriceForRing2 } from "../utils/price"
import logoUrl from "../assets/logo.png"
import styles from "./Ring.module.css"
import { useParams } from "react-router"
import { API_ENDPOINT } from ".."

const MIN_TEXT_LENGTH = 2
const MAX_TEXT_LENGTH = 6
const SIZES = new Array(30 * 2)
	.fill(0)
	.map((_, i) => (i >= 2 ? i / 2 : 0))
	.filter(Boolean)
const MATERIALS = ["YELLOW GOLD", "PINK GOLD", "WHITE GOLD", "SILVER"]

const Ring2 = () => {
	const { ticket } = useParams<any>()

	const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(true)
	const [mightTakeABitLoader, setMightTakeABitLoader] = useState<boolean>(false)

	const [size, setSize] = useState<number>(29)
	const [material, setMaterial] = useState<string>(MATERIALS[1])
	const [text, setText] = useState<string>("Sample")
	const [price, setPrice] = useState<number>(0)

	const shapeDiverApiRef = useRef<any | null>(null)
	const updateTextTimeoutRef = useRef<any>()

	const onBuyNowClick = async () => {
		setIsLoaderVisible(true)
		setMightTakeABitLoader(true)

		const downloadSTLResponse =
			await shapeDiverApiRef.current.exports.requestAsync({
				name: "Download",
				silent: true
			})
		const stlUrl = downloadSTLResponse.data.content[0].href

		const paramsRequest = shapeDiverApiRef.current.parameters.get()

		console.log("Params", paramsRequest)

		const params = paramsRequest.data

		const description = params
			.map((param: any) => `${param.name}: ${param.value}`)
			.join("<br>")

		await shapeDiverApiRef.current.scene.camera.zoomAsync()

		await new Promise((res) => setTimeout(res, 400))

		const screenshotRes =
			await shapeDiverApiRef.current.scene.getScreenshotAsync()

		const attachment = screenshotRes.data
		const compressedAttachment = await downscaleImage(
			attachment,
			"image/png",
			720,
			75
		)

		fetch(`${API_ENDPOINT}/buy`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				ring: 2,
				description,
				stlUrl,
				attachment: compressedAttachment
			})
		})
			.then((res) => res.json())
			.then((res) => {
				console.log("Response", res)

				if (res.success) {
					console.log("Redirecting...")
					window.location.href = res.redirectUrl
					return
				}

				alert(
					`Error: ${
						typeof res.error === "string"
							? res.error
							: JSON.stringify(res.error)
					}`
				)
			})
			.catch((err) => {
				console.error(err)
			})
			.finally(() => {
				setIsLoaderVisible(false)
				setMightTakeABitLoader(false)
			})
	}

	// Setup ShapeDiver
	useEffect(() => {
		if (!ticket) {
			alert("Wrong parameters")
			// window.close()
			return
		}

		// @ts-ignore
		const sdv = new window.SDVApp.ParametricViewer({
			container: document.getElementById("sdv-viewer-container"),
			ticket,
			modelViewUrl: "eu-central-1"
		})
		shapeDiverApiRef.current = sdv

		setIsLoaderVisible(false)

		console.log("ShapeDiver API ready", shapeDiverApiRef.current)

		setTimeout(() => {
			const paramsRequest = shapeDiverApiRef.current.parameters.get()
			console.log("Params", paramsRequest)
		}, 1000)
	}, [])

	useDidMountEffect(() => {
		shapeDiverApiRef.current &&
			shapeDiverApiRef.current.parameters
				.updateAsync({
					name: "Taglia",
					value: size.toString()
				})
				.then(console.log)
	}, [size])

	useDidMountEffect(() => {
		if (text.length > MIN_TEXT_LENGTH) {
			if (updateTextTimeoutRef.current) {
				clearTimeout(updateTextTimeoutRef.current)
			}

			updateTextTimeoutRef.current = setTimeout(() => {
				shapeDiverApiRef.current &&
					shapeDiverApiRef.current.parameters
						.updateAsync({
							name: "Text",
							value: text
						})
						.then((res: any) => console.log("Text updated", res))
			}, 500)
		}

		return () => {
			if (updateTextTimeoutRef.current) {
				clearTimeout(updateTextTimeoutRef.current)
				updateTextTimeoutRef.current = undefined
			}
		}
	}, [text])

	useDidMountEffect(() => {
		shapeDiverApiRef.current &&
			shapeDiverApiRef.current.parameters
				.updateAsync({
					name: "Material",
					value: MATERIALS.findIndex((_material) => _material === material)
				})
				.then(console.log)
	}, [material])

	// Update price
	useEffect(() => {
		setPrice(calculatePriceForRing2())
	}, [text, material])

	return (
		<div className={styles.container}>
			<nav className={styles.nav}>
				<img height="60%" className={styles.navLogo} src={logoUrl} />
				<Button className={styles.buyBtn} onClick={onBuyNowClick}>
					Prenota ora
				</Button>
			</nav>

			<main className={styles.main}>
				<div
					className={styles.splitScreenDivStyles}
					style={{ width: 100, height: 100 }}
					id="sdv-viewer-container"
				/>
				<div className={styles.splitScreenDivStyles}>
					<div className={styles.controlsWrapper}>
						<Heading className={styles.controlsTitle}>Size</Heading>
						<Select
							className={styles.controlsSelect}
							value={size}
							onChange={(e) => {
								setSize(Number(e.target.value))
							}}
						>
							{SIZES.map((_size, i) => (
								<option key={`size-${i}`} value={_size}>
									{_size.toLocaleString()}
								</option>
							))}
						</Select>

						<Heading className={styles.controlsTitle}>Materials</Heading>
						<Select
							className={styles.controlsSelect}
							value={material}
							onChange={(e) => {
								setMaterial(e.target.value)
							}}
						>
							{MATERIALS.map((_material, i) => (
								<option key={`material-${i}`} value={_material}>
									{_material}
								</option>
							))}
						</Select>

						<Heading className={styles.controlsTitle}>Text</Heading>
						<Input
							value={text}
							onChange={(e) => {
								if (e.target.value.length <= MAX_TEXT_LENGTH) {
									setText(e.target.value)
								}
							}}
							className={styles.controlsSelect}
						/>

						{price > 0 && (
							<Text
								className={[styles.controlsTitle, styles.controlsPrice].join(
									" "
								)}
								fontSize="2xl"
							>
								Price: {price}€
							</Text>
						)}
					</div>
				</div>

				<Loader
					isVisible={isLoaderVisible}
					mightTakeABit={mightTakeABitLoader}
				/>
			</main>
		</div>
	)
}

export default Ring2
