const DEFAULT_MATERIAL_PRICE = 650
const DEFAULT_DIAMOND_PRICE = 85

export const calculatePriceForRing1 = (lettersWithDiamondCount: number) => {
	return (
		DEFAULT_MATERIAL_PRICE + DEFAULT_DIAMOND_PRICE * lettersWithDiamondCount
	)
}

export const calculatePriceForRing2 = () => {
	return DEFAULT_MATERIAL_PRICE
}
