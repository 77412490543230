import styles from "./Loader.module.css"

type Props = {
	isVisible: boolean
	mightTakeABit?: boolean
}

function Loader(props: Props) {
	return (
		<div
			className={styles.loader}
			style={{
				opacity: props.isVisible ? 1 : 0,
				pointerEvents: props.isVisible ? "auto" : "none"
			}}
		>
			<svg
				className={styles.loaderSvg}
				version="1.1"
				id="L9"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 100 100"
				enableBackground="new 0 0 0 0"
				xmlSpace="preserve"
			>
				<path
					fill="#fff"
					d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
				>
					<animateTransform
						attributeName="transform"
						attributeType="XML"
						type="rotate"
						dur="1s"
						from="0 50 50"
						to="360 50 50"
						repeatCount="indefinite"
					/>
				</path>
			</svg>

			{props.mightTakeABit && (
				<span className={styles.loaderText}>It might take a bit...</span>
			)}
		</div>
	)
}

export default Loader
